<template>
  <base-popup
    :title="'Видео инструкция. ' + data.Name"
    :value="value"
    @input="$emit('input', $event)"
    :icon="'medexpert-play'"
    hide-save
    v-if="data"
  >
    <MaterialYoutube
      v-if="value"
      class="mt-6"
      :url="data.Url"
    ></MaterialYoutube>
  </base-popup>
</template>
<script>
import BasePopup from "@/layouts/BasePopup.vue";
import MaterialYoutube from "@/views/knowledgeBase/components/materials/youtube.vue";
import tourApi from "../api/tourApi";
export default {
  components: { BasePopup, MaterialYoutube },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.complete();
  },
  methods: {
    complete() {
      tourApi.help.video.complete(this.data.Id);
    },
  },
};
</script>
